import { createSlice } from "@reduxjs/toolkit";
import "./textCommentSlice.css";

// Ініціальний стан для текстових коментарів
const initialState = {
  comments: [],
  loading: false,
  error: null,
  postId: null,
  showTextComment: false, // Новий стан для відображення текстового коментаря
};

// Створення Slice
const textCommentSlice = createSlice({
  name: "textComment",
  initialState,
  reducers: {
    // Дія для початку завантаження коментарів
    fetchCommentsStart(state) {
      state.loading = true;
      state.error = null;
    },
    // Дія для успішного завантаження коментарів
    fetchCommentsSuccess(state, action) {
      state.loading = false;
      state.comments = action.payload;
    },
    // Дія для помилки при завантаженні коментарів
    fetchCommentsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    // Дія для додавання нового коментаря
    addComment(state, action) {
      state.comments.push(action.payload);
    },
    // Дія для видалення коментаря
    removeComment(state, action) {
      state.comments = state.comments.filter(
        (comment) => comment.id !== action.payload
      );
    },
    // Дія для перемикання відображення текстового коментаря
    toggleTextComment(state) {
      state.showTextComment = !state.showTextComment;
    },
    setPostId(state, action) {
      state.postId = action.payload;
    },
  },
});

// Експорт дій для використання в компонентах
export const {
  fetchCommentsStart,
  fetchCommentsSuccess,
  fetchCommentsFailure,
  addComment,
  removeComment,
  toggleTextComment,
  setPostId,
} = textCommentSlice.actions;

// Експорт reducer для налаштування store
export default textCommentSlice.reducer;
