import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer'; // Імпортуємо rootReducer

// Створення і конфігурація store
const store = configureStore({
  reducer: rootReducer, // Використовуємо rootReducer
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(customMiddleware), // Опціонально додати middleware
});

export default store;
