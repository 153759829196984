import { useRef, useState, useMemo, useEffect } from 'react';
import Webcam from 'react-webcam';
import Result from './Result';

import useRecordHandler from 'hooks/useRecordHandler';

import { fileToDataURI } from 'utils/converter';

import './camera-viewer.css';

export default function CameraViewer({ setCameraOn }) {
    const camera = useRef();
    const { isRecording, isPaused, seconds, start, pause, resume, stop, video, clearVideo } =
        useRecordHandler(camera);

    const [mode, setMode] = useState('photo');

    const [photo, setPhoto] = useState(null);

    const [width, setWidth] = useState(400);
    const [height, setHeight] = useState(900);
    const [facingMode, setFacingMode] = useState('user');
    const videoConstraints = useMemo(() => {
        return {
            width: width,
            height: height,
            facingMode,
            aspectRatio: width / height,
        };
    }, [facingMode, width, height]);

    useEffect(() => {
        setHeight(window.screen.height);
        setWidth(window.screen.width);
    }, []);

    return (
        <section className='camera-viewer'>
            {useMemo(
                () =>
                    photo || video() ? (
                        <Result
                            result={photo ? photo : video()}
                            width={width}
                            height={height}
                            setResult={photo ? setPhoto : clearVideo}
                            isPhoto={mode == 'photo'}
                            setCameraOn={setCameraOn}
                        />
                    ) : (
                        <></>
                    ),
                [photo, video, width, height]
            )}
            {useMemo(
                () =>
                    isRecording || isPaused ? (
                        <></>
                    ) : (
                        <div className='selector'>
                            <div
                                className='photo'
                                onClick={() => setMode('photo')}
                                style={{
                                    borderBottomColor: mode == 'photo' ? 'white' : 'transparent',
                                    opacity: mode == 'photo' ? '1' : '30%',
                                }}
                            >
                                Фото
                            </div>
                            <div
                                className='video'
                                onClick={() => setMode('video')}
                                style={{
                                    borderBottomColor: mode == 'video' ? 'white' : 'transparent',
                                    opacity: mode == 'video' ? '1' : '30%',
                                }}
                            >
                                Відео
                            </div>
                        </div>
                    ),
                [isRecording, isPaused, mode]
            )}

            {useMemo(
                () =>
                    mode == 'photo' ? (
                        <div className='photo-loader'>
                            <input
                                type='file'
                                name='photo'
                                accept='capture=camera,image/*'
                                onChange={(event) => {
                                    fileToDataURI(event.target.files[0]).then((data) =>
                                        setPhoto(data)
                                    );
                                    event.target.value = null;
                                }}
                            />
                            <img src='/svg/gallery.svg' alt='gallery' width='100' height='100' />
                        </div>
                    ) : (
                        <></>
                    ),
                [mode]
            )}

            <Webcam
                audio={true}
                muted={true}
                ref={camera}
                width={width}
                height={height}
                screenshotFormat='image/jpg'
                videoConstraints={videoConstraints}
                disablePictureInPicture={true}
                style={{ objectFit: 'cover' }}
            />
            <div className='controller'>
                {useMemo(
                    () =>
                        mode == 'video' ? (
                            <div className='timer'>
                                <div
                                    className='line'
                                    style={{
                                        background: `linear-gradient(90deg, white ${
                                            ((10 - seconds) / 10) * 100
                                        }%, #b8b8b8a6 0%)`,
                                    }}
                                ></div>
                                <h2>{`${10 - Math.floor(seconds)} s`}</h2>
                            </div>
                        ) : (
                            <></>
                        ),
                    [mode, seconds]
                )}
                <button
                    className='close-capture'
                    onClick={() => {
                        if (isPaused) resume();
                        else if (isRecording) pause();
                        else setCameraOn(false);
                    }}
                >
                    {isRecording || isPaused ? (
                        <img src='/svg/pause.svg' alt='pause' />
                    ) : (
                        <img src='/svg/close-camera.svg' alt='close-camera' />
                    )}
                </button>
                <button
                    className='take-photo'
                    onClick={() => {
                        if (mode == 'photo') setPhoto(camera.current.getScreenshot());
                        if (mode == 'video') isRecording || isPaused ? stop() : start();
                    }}
                    style={{
                        background: mode == 'photo' ? 'none' : 'red',
                        borderRadius: isRecording || isPaused ? '25%' : '50%',
                    }}
                ></button>
                <button
                    className='change-camera'
                    onClick={() => setFacingMode(facingMode == 'user' ? 'environment' : 'user')}
                >
                    <img src='/svg/change-camera.svg' alt='close-camera' />
                </button>
            </div>
        </section>
    );
}
