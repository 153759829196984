// src/app/rootReducer.js
import { combineReducers } from "@reduxjs/toolkit";
import loaderReducer from "../features/loading/loadingSlice";
import authReducer from "../features/auth/authSlice";
import userReducer from "features/userProfile/userProfileSlice";
import textCommentReducer from "features/textComment/textCommentSlice";
import editProfileReducer from "features/editProfileSlice/editProfileSlice";

// Комбінуємо всі редюсери
const rootReducer = combineReducers({
  loading: loaderReducer,
  auth: authReducer,
  user: userReducer,
  textComment: textCommentReducer,
  editProfile: editProfileReducer,
  // можна додати більше редюсерів сюди
});

export default rootReducer;
