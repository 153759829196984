import React from "react";

const DeletePostLightbox = ({ isVisible, onClose, onConfirm, postId }) => {
  if (!isVisible) return null;

  return (
    <div className="lightbox delete_my_post_box" style={{ visibility: "visible", opacity: 1 }}>
      <div className="box">
        <div className="header">
          <span>Видалити допис</span>
          <div className="close_delete_my_post_box" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 501 501">
              <path className="fil0" d="M37.29 6.53l212.97 212.96 212.97 -212.98c8.44,-8.43 22.24,-8.43 30.67,0l0.02 0.03c8.45,8.43 8.45,22.24 0,30.67l-212.96 212.97 212.96 212.97c8.44,8.44 8.44,22.24 0,30.67l-0.02 0.02c-8.43,8.45 -22.24,8.45 -30.67,0l-212.97 -212.96 -212.96 212.95c-8.44,8.45 -22.24,8.45 -30.68,0l-0.01 -0.01c-8.45,-8.44 -8.45,-22.24 0,-30.67l212.95 -212.97 -212.95 -212.96c-8.45,-8.44 -8.45,-22.24 0,-30.68l0.01 -0.01c8.44,-8.45 22.24,-8.45 30.67,0z"/>
            </svg>
          </div>
        </div>
        <div className="content">
          <span>Дійсно видалити цей допис?</span>
          <div>
            <input type="hidden" id="trash_post_id" value={postId} />
            <div className="button delete_yes" onClick={onConfirm}>Так</div>
            <div className="button delete_no" onClick={onClose}>Скасувати</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeletePostLightbox;
