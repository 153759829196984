import Comment from './Comment';

import { useState, useEffect, useMemo } from 'react';
import { useAudioRecorder } from 'react-audio-voice-recorder';

import { apiRequest, fetchAllAudios } from 'utils/api';
import { secondsToFullTime } from 'utils/converter';

import './audio-comment.css';

export default function AudioComment({ setAudioComments, postId }) {
    const { isRecording, startRecording, stopRecording, recordingBlob, recordingTime } =
        useAudioRecorder();

    const [comments, setComments] = useState([]);
    const [audios, setAudios] = useState([]);

    const [refetch, setRefetch] = useState(false);
    useEffect(() => {
        apiRequest('get_audio_comments.php', 'POST', { post_id: postId }).then((data) => {
            console.log(data.comments);
            if (data.comments && data.comments.length > 0) {
                setComments(data.comments);
                fetchAllAudios(data.comments).then((audios) => setAudios(audios));
            }
        });
        setTimeout(() => {
            const comments = document.querySelector('section.audio-comments');
            comments.style.animation = 'none';
        }, 400);
    }, [refetch]);

    useEffect(() => {
        if (recordingBlob)
            apiRequest('add_audio_comment.php', 'POST', {
                postid: postId,
                voice: recordingBlob,
            }).then(() =>
                setTimeout(() => {
                    setRefetch(!refetch);
                }, 500)
            );
    }, [recordingBlob]);

    const handleDeleteComment = (commentId) => {
        setComments(comments.filter((comment) => comment.id !== commentId));
    };

    return (
        <section className='audio-comments'>
            <img
                className='close'
                src='/svg/audio-comments/close.svg'
                alt='close'
                style={{ width: '1.5rem', height: '1.5rem' }}
                onClick={() => {
                    const comments = document.querySelector('section.audio-comments');
                    comments.style.animation = 'slide-top ease-in-out 300ms forwards reverse';
                    setTimeout(() => setAudioComments(false), 400);
                }}
            />
            {comments.length === 0 ? (
                <h3>No comments</h3>
            ) : (
                comments &&
                audios.length &&
                comments.length === audios.length &&
                comments.map((comment, index) => (
                    <Comment
                        key={comment.id}
                        comment={comment}
                        audio={audios[index]}
                        onDelete={handleDeleteComment}
                    />
                ))
            )}
            <div className='record' style={{ transform: `translateY(${isRecording ? 1 : 4}rem)` }}>
                <img
                    className='record-background'
                    src='/svg/audio-comments/record-background.svg'
                    alt='background'
                />
                <button
                    id='round-gradient'
                    onClick={() => (isRecording ? stopRecording() : startRecording())}
                >
                    {isRecording ? <div /> : <img src='/svg/voice-comment.svg' alt='button' />}
                </button>
                <h2>{secondsToFullTime(recordingTime)}</h2>
            </div>
        </section>
    );
}
