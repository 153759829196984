import React from "react";

function Lightbox({ isVisible, onClose, onConfirm, postId }) {
  if (!isVisible) {
    return null; // Якщо лайтбокс не видимий, повертаємо null
  }

  return (
    <div className="lightbox delete_my_favourite_box" style={{ visibility: "visible", opacity: 1 }}>
      <div className="box">
        <div className="header">
          <span>Видалити з улюблених</span>
          <div className="close_delete_my_favourite_box" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" style={{ fillRule: "evenodd", clipRule: "evenodd" }} viewBox="0 0 501 501">
              <path className="fil0" d="M37.29 6.53l212.97 212.96 212.97 -212.98c8.44,-8.43 22.24,-8.43 30.67,0l0.02 0.03c8.45,8.43 8.45,22.24 0,30.67l-212.96 212.97 212.96 212.97c8.44,8.44 8.44,22.24 0,30.67l-0.02 0.02c-8.43,8.45 -22.24,8.45 -30.67,0l-212.97 -212.96 -212.96 212.95c-8.44,8.45 -22.24,8.45 -30.68,0l-0.01 -0.01c-8.45,-8.44 -8.45,-22.24 0,-30.67l212.95 -212.97 -212.95 -212.96c-8.45,-8.44 -8.45,-22.24 0,-30.68l0.01 -0.01c8.44,-8.45 22.24,-8.45 30.67,0z"></path>
            </svg>
          </div>
        </div>
        <div className="content">
          <span>Дійсно видалити з улюблених?</span>
          <div>
            <input type="hidden" id="favourite_post_id" value={postId} />
            <div className="button fav_delete_yes" onClick={onConfirm}>
              Так
            </div>
            <div className="button" onClick={onClose}>
              Скасувати
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Lightbox;
