import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiRequest } from "utils/api";
import { toggleTextComment } from "features/textComment/textCommentSlice";
import {
  setUser,
  toggleUserProfile,
} from "features/userProfile/userProfileSlice";
import SubComments from "./SubComments"; // Імпортуємо новий компонент

function TextComment() {
  const dispatch = useDispatch();
  const postId = useSelector((state) => state.textComment.postId);
  const [comments, setComments] = useState([]);
  const [error, setError] = useState(null);
  const [newCommentText, setNewCommentText] = useState(""); // Стан для збереження тексту нового коментаря
  const showTextComment = useSelector(
    (state) => state.textComment.showTextComment
  );
  // Retrieve user ID from Redux state or localStorage
  const user = useSelector((state) => state.auth.user);
  const userId = user ? user.id : JSON.parse(localStorage.getItem("user"))?.id;

  useEffect(() => {
    if (!postId) return; // Перевірка, чи переданий postId

    // Запит для отримання коментарів з поста
    apiRequest("get_text_comments.php", "GET", { post_id: postId })
      .then((data) => {
        if (data.status === "error") {
          setError(
            "This post has been removed, or you do not have permission to view it"
          );
        } else if (data.comments && data.comments.message === "No comments") {
          setError("No comments available");
        } else if (Array.isArray(data.comments) && data.comments.length === 0) {
          setError("No comments available");
        } else if (!Array.isArray(data.comments)) {
          setError("An error occurred while loading comments.");
        } else {
          setComments(data.comments);
          setError(null);
        }
      })
      .catch((error) => {
        console.error("Error loading comments:", error);
        setError("An error occurred while loading comments.");
      });
  }, [postId]);

  useEffect(() => {
    const comments = document.querySelector(".text_messages");

    if (comments) {
      if (showTextComment) {
        comments.style.animation = "slide-in 300ms ease-in-out forwards";
      } else {
        comments.style.animation = "slide-out 300ms ease-in-out forwards";
        setTimeout(() => {
          // Reset the state to hide the component after the slide-out animation
          dispatch(toggleTextComment());
        }, 300);
      }
    }
  }, [showTextComment, dispatch]);

  const handleReplyClick = (commentId, nickname) => {
    document.getElementById("reply_to_id").value = commentId;
    document.querySelector(".reply_to b").textContent = nickname;
    document.getElementById("send_comment_text").focus();
    document.querySelector(".reply_to").style.display = "block";
  };

  const handleUserProfileClick = (userId) => {
    console.log("user_profile click");
    // Логіка для завантаження профілю користувача
    dispatch(setUser(userId));
    dispatch(toggleUserProfile());
  };

  const handleLikeComment = (commentId, isLiked) => {
    // Новий стан лайку - якщо був лайк, тепер знімаємо лайк і навпаки
    const newIsLiked = !isLiked;

    // Відправка запиту на сервер
    apiRequest("like_comment.php", "POST", {
      comment_id: commentId,
      isLiked: newIsLiked, // Відправляємо новий стан
    })
      .then((data) => {
        if (data.status === "success") {
          // Оновлюємо стан коментарів, знаходимо лайкнутий коментар та змінюємо його стан
          setComments((prevComments) =>
            prevComments.map((comment) =>
              comment.id === commentId
                ? {
                    ...comment,
                    isLiked: newIsLiked,
                    like_count: data.like_count,
                  }
                : comment
            )
          );
        } else {
          alert("Failed to like/unlike comment");
        }
      })
      .catch((error) => {
        console.error("Error liking/unliking comment:", error);
      });
  };

  const handleClose = () => {
    const comments = document.querySelector(".text_messages");

    if (comments) {
      comments.style.animation = "slide-top ease-in-out 300ms forwards reverse";

      setTimeout(() => {
        dispatch(toggleTextComment());
      }, 300);
    }
  };

  // Функція для обробки введення тексту коментаря
  const handleCommentInputChange = (e) => {
    setNewCommentText(e.target.value);
  };

  // Функція для надсилання нового коментаря
  const handleSendComment = (e) => {
    e.preventDefault();
    const replyToId =
      parseInt(document.getElementById("reply_to_id").value, 10) || 0;

    if (!newCommentText.trim()) {
      alert("Please enter a comment");
      return;
    }

    // Відправка коментаря на сервер
    apiRequest("add_comment.php", "POST", {
      post_id: postId,
      text: newCommentText,
      parent_id: replyToId,
    })
      .then((data) => {
        if (data.status === "success") {
          // Додавання нового коментаря в список
          const newComment = {
            id: data.comment.id,
            text: newCommentText,
            time: "0m", // Новий коментар додається з часом "0 хвилин тому"
            user_id: data.comment.user_id,
            avatar: data.comment.avatar || "/img/icons/default_avatar.png",
            nickname: data.comment.nickname,
            like_count: 0,
            isLiked: false,
            parent: replyToId,
          };
          console.log("newComment", newComment);

          // Додаємо новий коментар до масиву
          setComments((prevComments) => [...prevComments, newComment]);

          setNewCommentText(""); // Очистка поля введення
          document.querySelector(".reply_to").style.display = "none"; // Сховати блок відповіді
        } else {
          alert("Failed to add comment");
        }
      })
      .catch((error) => {
        console.error("Error sending comment:", error);
      });
  };

  // Рендеринг одного коментаря
  const renderComment = (comment, level = 1) => {
    const commentClass = level === 1 ? "comment" : "comment level2";

    return (
      <div className={commentClass} key={comment.id}>
        <div
          className="avatar user_profile"
          data-userid={comment.user_id}
          onClick={() => handleUserProfileClick(comment.user_id)}
        >
          <img
            src={
              comment.avatar ||
              process.env.REACT_APP_IMG_DOMAIN + "/img/icons/default_avatar.png"
            }
            alt="User Avatar"
          />
        </div>
        <div className="comment_data">
          <div className="nickname">{comment.nickname}</div>
          <div className="time">{comment.time}</div>
          <div className="text">{comment.text}</div>
          <div
            className="w100 actions"
            data-comment_id={comment.id}
            data-reply_to_nickname={comment.nickname}
          >
            <div
              className="ans"
              onClick={() => handleReplyClick(comment.id, comment.nickname)}
            >
              Reply
            </div>
            {Number(userId) === Number(comment.user_id) && (
              <div onClick={() => handleRemoveComment(comment.id)}>Remove</div>
            )}
            <div>Translate</div>
            <div>
              <div
                className="hearts"
                data-comment_id={comment.id}
                onClick={() => handleLikeComment(comment.id, comment.isLiked)}
              >
                {comment.isLiked ? (
                  <svg
                    className="heart_set"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"></path>
                  </svg>
                ) : (
                  <svg
                    className="heart_unset"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M244 84L255.1 96L267.1 84.02C300.6 51.37 347 36.51 392.6 44.1C461.5 55.58 512 115.2 512 185.1V190.9C512 232.4 494.8 272.1 464.4 300.4L283.7 469.1C276.2 476.1 266.3 480 256 480C245.7 480 235.8 476.1 228.3 469.1L47.59 300.4C17.23 272.1 0 232.4 0 190.9V185.1C0 115.2 50.52 55.58 119.4 44.1C164.1 36.51 211.4 51.37 244 84C243.1 84 244 84.01 244 84L244 84zM255.1 163.9L210.1 117.1C188.4 96.28 157.6 86.4 127.3 91.44C81.55 99.07 48 138.7 48 185.1V190.9C48 219.1 59.71 246.1 80.34 265.3L256 429.3L431.7 265.3C452.3 246.1 464 219.1 464 190.9V185.1C464 138.7 430.4 99.07 384.7 91.44C354.4 86.4 323.6 96.28 301.9 117.1L255.1 163.9z"></path>
                  </svg>
                )}
              </div>
              <div className="like_count">{comment.like_count}</div>
            </div>
          </div>
          <SubComments
            parentId={comment.id}
            comments={comments}
            renderComment={renderComment}
          />
        </div>
      </div>
    );
  };

  // Рендеринг коментарів та підкоментарів
  const renderComments = () => {
    if (error) {
      return <div className="no_comments">{error}</div>;
    }
    const parentComments = comments.filter((comment) => comment.parent === 0);
    return parentComments.map((comment) => (
      <React.Fragment key={comment.id}>{renderComment(comment)}</React.Fragment>
    ));
  };

  const handleRemoveComment = (commentId) => {
    // Send a request to the API to remove the comment
    apiRequest("remove_comment.php", "POST", { comment_id: commentId, type: 'text' })
      .then((response) => {
        if (response.status === "success") {
          // Remove the comment from the state if the response is successful
          setComments((prevComments) =>
            prevComments.filter((comment) => comment.id !== commentId)
          );
        } else {
          alert("Failed to remove comment");
        }
      })
      .catch((error) => {
        console.error("Error removing comment:", error);
      });
  };

  return (
    <div>
      <div
        className="comments_block text_messages"
        style={{ zIndex: 100, visibility: "visible", top: "0px" }}
      >
        <div className="top_nav">
          <div className="button_left">
            <svg
              className="set_back"
              onClick={handleClose}
              xmlns="http://www.w3.org/2000/svg"
              xmlSpace="preserve"
              style={{
                fill: "black",
                fillRule: "evenodd",
                clipRule: "evenodd",
              }}
              viewBox="0 0 4700 8900"
            >
              <path
                className="fil0"
                d="M4589.92 625.66c118.95,-150.59 105.84,-371.54 -35.63,-506.49l-0.01 -0.01c-150.06,-143.14 -389.92,-137.46 -533.06,12.59l-3866.91 4054.06c-78.5,82.32 -112.23,191.64 -101.94,297.2 6.63,83.28 40.65,164.85 102.45,229.68l3865.71 4055.19c143.09,150.1 382.96,155.85 533.06,12.77l0 -0.01c142.17,-135.52 154.81,-357.82 34.19,-508.48l-3393.15 -3559.47c-61.8,-64.83 -95.82,-146.4 -102.45,-229.68 -10.29,-105.56 23.44,-214.88 101.94,-297.2l3395.8 -3560.15z"
              ></path>
            </svg>
          </div>
          <div
            className="button_right"
            style={{ alignItems: "center", padding: "0 7px" }}
          ></div>
        </div>
        <div className="comments_list">
          <input type="hidden" id="comment_post_id" value={postId} />
          {renderComments()}
        </div>
        <div className="reply_to">
          <input type="hidden" id="reply_to_id" />
          Reply to <b></b>
          <span className="cancel">Cancel</span>
        </div>
        <div className="send_comment">
          <form onSubmit={handleSendComment}>
            <input
              type="text"
              id="send_comment_text"
              placeholder="Your comment"
              value={newCommentText}
              onChange={handleCommentInputChange}
            />
            <button type="submit" className="send b-grad radius-max">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve"
                style={{ fillRule: "evenodd", clipRule: "evenodd" }}
                viewBox="0 0 7000 5100"
              >
                <polygon
                  className="fil0"
                  points="58.71,2577.39 704.17,1931.94 2565.69,3793.47 6293.43,47.02 6938.9,692.46 2556.34,5075.02 "
                ></polygon>
              </svg>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default TextComment;
