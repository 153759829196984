import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toggleAuthorize, setUser, toggleSettings } from "features/auth/authSlice";
import { apiRequest } from "utils/api";

function ForgotPassword({setShowForgotPassword}) {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [step, setStep] = useState(1); // Step 1: Enter email, Step 2: Enter OTP
  const dispatch = useDispatch();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleEmailSubmit = async (event) => {
    event.preventDefault();
    setError("");

    try {
      const response = await apiRequest("restore.php", "POST", {
        email: email,
      });

      if (response.status === "success") {
        setStep(2); // Move to OTP step
      } else {
        setError(response.message || "Failed to send OTP.");
      }
    } catch (err) {
      setError("Network error or server is down");
      console.error("Error during restore request:", err);
    }
  };

  const handleOtpSubmit = async (event) => {
    event.preventDefault();
    setError("");

    try {
      const response = await apiRequest("auth.php", "POST", {
        inputEmail: email,
        otp: otp,
      });

      if (response.token) {
        localStorage.setItem("token", response.token);
        dispatch(setUser(response));
        dispatch(toggleSettings());
        dispatch(toggleAuthorize());
      } else {
        setError(response.Error || "Invalid OTP.");
      }
    } catch (err) {
      setError("Network error or server is down");
      console.error("Error during OTP submission:", err);
    }
  };

  const handleCloseClick = () => {
    //dispatch(toggleAuthorize()); // Close the component
    setShowForgotPassword(false);
  };

  return (
    <div id="forgot-password">
      <div className="top_nav">
        <div className="button_right">
          <svg
            className="close"
            onClick={handleCloseClick}
            xmlns="http://www.w3.org/2000/svg"
            style={{ fillRule: "evenodd", clipRule: "evenodd" }}
            viewBox="0 0 501 501"
          >
            <path
              className="fil0"
              d="M37.29 6.53l212.97 212.96 212.97 -212.98c8.44,-8.43 22.24,-8.43 30.67,0l0.02 0.03c8.45,8.43 8.45,22.24 0,30.67l-212.96 212.97 212.96 212.97c8.44,8.44 8.44,22.24 0,30.67l-0.02 0.02c-8.43,8.45 -22.24,8.45 -30.67,0l-212.97 -212.96 -212.96 212.95c-8.44,8.45 -22.24,8.45 -30.68,0l-0.01 -0.01c-8.45,-8.44 -8.45,-22.24 0,-30.67l212.95 -212.97 -212.95 -212.96c-8.45,-8.44 -8.45,-22.24 0,-30.68l0.01 -0.01c8.44,-8.45 22.24,-8.45 30.67,0z"
            />
          </svg>
        </div>
      </div>
      <h2>Forgot Password</h2>
      <form onSubmit={step === 1 ? handleEmailSubmit : handleOtpSubmit}>
        {step === 1 ? (
          <>
            <p>
              Type your E-mail and we will send you a temperer code to log in
            </p>
            <input
              name="email"
              type="text"
              placeholder="Your email"
              onChange={handleEmailChange}
            />
          </>
        ) : (
          <div>
            <p>We sent you one-time password (OTP) to your E-mail.</p>
            <input
                name="otp"
                type="text"
                placeholder="Enter the code from email"
                onChange={handleOtpChange}
            />
          </div>
        )}
        <span id="forgot_password_error" style={{ color: "red" }}>
          {error}
        </span>
        <button type="submit" className="accept shadow radius-max b-grad">
          <span>{step === 1 ? "Send Code" : "Confirm"}</span>
        </button>
      </form>
    </div>
  );
}

export default ForgotPassword;
